* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: sans-serif;
}



.App {
    opacity: 10px;
    background: rgba(30, 143, 255, 0.072);
}




.App>img {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
}




.AppBtn {
    width: 100%;
    height: 55px;
    background: dodgerblue;
    border-radius: 100px;
    border: none;
    border: none;
    font-size: 20px;
    color: white;
    position: fixed;
    bottom: 0;
    left: 0;
    animation: 2s linear infinite;
}

@keyframes AppBtn {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(100%);
    }
}

@media screen and (max-width:1100px) {
    .App>img{
        width: 300px;
    }
}