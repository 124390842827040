* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: sans-serif;
}


.ContenierMedmeCrm {
    width: 100%;
    background: rgba(0, 0, 47, 0.808);
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    border-radius: 10px;
}



.MedemCrmvaLms {
    width: 100%;
    height: 200px;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}


.Medme {
    width: 65%;
    height: 100%;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 22px;
    text-align: center;
}



.Medme>h1 {
    color: gold;
    font-size: 38px;
}


.Medme>h2 {
    color: white;
    font-size: 27px;
}


.MedmeTanishuv {
    width: 70%;
    height: 490px;
    background: white;
    padding: 10px;
    border-radius: 10px;
    margin: 15px;

}


@media screen and (max-width:325px) {

    .MedmeTanishuv {
        width: 300px;
        height: 300px;
    }
}