* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: sans-serif;
}

.buttonGeneric {
    width: 100%;
    height: 28px;
    background: transparent;
    border: none;
    cursor: pointer;
}


.buttonGeneric:active {
    transform: scale(.98);
}