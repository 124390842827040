* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: sans-serif;
}



.mySwiper {
    width: 100%;
    height: 100vh;
    background: rgb(0, 65, 99);
}


.SwiperSlide1 {
    display: flex;
    flex-direction: column;
    text-align: center;
}



.SwiperSlideH1 {
    width: 100%;
    height: 15vh;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 20px;
}


.SwiperSlidePbox {
    width: 100%;
    height: 60vh;
    background: none;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}


.SwiperSlideP {
    width: 590px;
    height: 40vh;
    background: none;
    font-size: 27px;
    text-align: center;
}



.SwiperSlideImg {
    width: 100%;
    height: 300px;
    background: rgb(0, 65, 99);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 20px;
    color: white;
    gap: 20px;
}


.ImgBox {
    width: 130px;
    height: 130px;
    background: dodgerblue;
    border-radius: 130px;
}


.ImgBox>img {
    width: 100%;
    height: 100%;
    border-radius: 130px;
}


.YozuvBox {
    width: 500px;
    height: 60px;
    background: none;
}


.SwiperSlide2P {
    width: 570px;
    height: 40vh;
    background: none;
    font-size: 27px;
    text-align: center;
}


.SwiperSlide3P {
    width: 570px;
    height: 40vh;
    background: none;
    font-size: 16px;
    text-align: center;
}


.SwiperSlide4P {
    width: 570px;
    height: 40vh;
    background: none;
    font-size: 27px;
    text-align: center;
}



.SwiperSlide5P {
    width: 570px;
    height: 40vh;
    background: none;
    font-size: 23.1px;
    text-align: center;
}



.SwiperBtn {
    width: 100%;
    height: 450px;
    background: rgb(0, 65, 99);
}


.SorovBtn {
    width: 100%;
    height: 100px;
    background: rgb(0, 65, 99);
    display: flex;
    justify-content: center;
    align-items: end;
}


.SorovBtn>button {
    width: 450px;
    height: 60px;
    border-radius: 20px;
    background: gold;
    color: #000;
    border: none;
    border: solid 2px black;
    font-size: 22px;
}



.YozuvBtn {
    width: 100%;
    height: 150px;
    background: none;
    font-size: 20px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}


.NumerBtn {
    width: 100%;
    height: 100px;
    background: none;
    font-size: 20px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5px;
}



.IconBtn {
    width: 100%;
    height: 100px;
    background: none;
    font-size: 40px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}



.FooterDemo {
    width: 100%;
    height: 70px;
    background: #000;
}